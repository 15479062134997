<!-- eslint-disable vue/max-len -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="container-responsive" ref="container">
    <div class="row" aria-label="Configuration Canvas">
      <div class="compare-feature-importance d-flex">
        <div class="configurations flex-grow-1 col-md-10">
          <div class="global-actions mb-3">
            <button type="button" ref="addButton" @click="addConfiguration" :disabled="!inspectorTreeConfiguration" class="btn btn-primary">Add</button>
            <button type="button" ref="deleteButton" @click="trashSelectedConfigurations" class="btn btn-danger" :disabled="!isAnyConfigurationSelected">Trash</button>
          </div>
          <div class="d-flex flex-wrap">
            <div v-for="(conf, index) in configurations" ref="configPanels" :key="'conf-' + index" class="config-panel card mx-2 my-2" :class="{ 'border-selected': conf.configurationSelected }" @click="toggleSelectConfiguration(index)">
              <div class="card-body">
                <div class="card-title d-flex justify-content-between align-items-center">
                  <h6>Details {{index + 1}}</h6>
                </div>
                <div class="configurationDetails smaller-text">
                  <p>Input Data: {{ conf.inputDataSelected }}</p>
                  <p>Model: {{ conf.modelSelected }}</p>
                  <p>Tree Depth: {{ conf.maxTreeDepth }}</p>
                  <p>Test/Train Split: {{ conf.testTrainSplit }}</p>
                  <p
                    v-if="conf.modelSelected === 'decisionTree'">Splitter: {{ conf.splitterSelected }}</p>
                  <p
                    v-if="conf.modelSelected === 'decisionTree'">Criterion: {{ conf.criterionSelected }}</p>
                  <p>RandomState: {{ conf.randomStateSelected ? conf.randomStateSelected : 'None' }}</p>
                  <p>Targets: {{ targetLabels(conf) }}</p>
                  <p
                    v-if="conf.modelSelected !== 'decisionTree'">Learning Rate: {{ conf.learningRate }}</p>
                  <p
                    v-if="conf.modelSelected !== 'decisionTree'">Boosting Rounds: {{ conf.boostingRounds }}</p>
                </div>
                <p class="smaller-text">Test Accuracy: {{ (stats[index]?.test_accuracy * 100).toFixed(1)}}%</p>
                <p class="smaller-text">Training Accuracy: {{ (stats[index]?.training_accuracy * 100).toFixed(1) }}%</p>
                <div v-if="stats[index] && conf.modelSelected !== 'decisionTree'" class="smaller-text">
                  <p>Total Trees Used: {{ (stats[index].total_num_trees)}}</p>
                </div>
                <LoadingSpinner v-if="configurationsLoading[index]" />
                <div v-if="svgOutputs[index]" class="svg-container" @click="openSVG(svgOutputs[index])" @keypress="openSVG(svgOutputs[index])">
                  <div v-html="svgOutputs[index]" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref="inspector" class="inspector card mx-2 my-2 col-md-2">
          <div class="card-body">
            <h6 class="card-title">Configuration</h6>
            <div class="form-group">
              <label for="modelSelector" class="form-label smaller-text">Model:</label>
              <select class="form-select form-select-sm" id="modelSelector" v-model="modelSelected" @change="modelsChanged">
                <option value="decisionTree">Decision Tree</option>
                <option value="xgboost">XGBoost</option>
              </select>
            </div>
            <div v-show="modelSelected === 'xgboost'">
              <DecisionForestControls
                @controlsUpdated="updateForestConfiguration"
              />
            </div>
            <div v-show="modelSelected === 'decisionTree'">
              <DecisionTreeControls
                :hide-layout="true"
                @controlsUpdated="updateTreeConfiguration"
              />
            </div>
            <div class="form-group">
              <label for="importanceSelector" class="form-label smaller-text">Auxiliary Graphs:</label>
              <select
                class="form-select form-select-sm"
                id="importanceSelector"
                v-model="importanceSelected"
                @change="fetchFeatureImportance">
                <option value="shapley_summary">Shapley Summary</option>
                <option v-if="shouldShowShapleyOptions" value="shapley_beeswarm">Shapley Beeswarm</option>
                <option v-if="shouldShowShapleyOptions" value="shapley_scatter">Shapley Scatter</option>
                <option v-if="modelSelected === 'decisionTree'" value="gini">Gini</option>
                <option v-if="modelSelected === 'decisionTree' && numberOfTargetsSelected === 1" value="dtreeviz">dtreeviz</option>
              </select>
            </div>
            <div class="form-check mt-2">
              <label class="smaller-text" for="includeLegend">
                Include Legend
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                id="includeLegend"
                v-model="includeLegend"
                @change="fetchFeatureImportance">
            </div>
          </div>
        </div>
      </div>
      <div
        ref="explanation_section">
        <explanation-panel
          :graphsToExplain="graphsToExplain"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ExplanationPanel from './ExplanationPanel.vue';
import DecisionForestControls from './DecisionForestControls.vue';
import DecisionTreeControls from './DecisionTreeControls.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import {
  openSVGInNewTab, getTreeFeatureImportanceGraph,
  getForestFeatureImportanceGraph, getForestStats,
} from './lib/utils';

export default {
  components: {
    ExplanationPanel,
    DecisionTreeControls,
    DecisionForestControls,
    LoadingSpinner,
  },
  data() {
    return {
      configurations: [],
      inspectorTreeConfiguration: null,
      inspectorForestConfiguration: null,
      svgOutputs: [],
      configurationsLoading: [],
      stats: [],
      modelSelected: 'decisionTree',
      importanceSelected: 'gini',
      includeLegend: true,
    };
  },
  computed: {
    isAnyConfigurationSelected() {
      return this.configurations.some((conf) => conf.configurationSelected);
    },
    selectedConfiguration() {
      return this.configurations.find((conf) => conf.configurationSelected);
    },
    areMultipleConfigurationSelected() {
      return this.configurations.filter((conf) => conf.configurationSelected).length > 1;
    },
    shouldShowShapleyOptions() {
      return this.modelSelected !== 'decisionTree' || this.numberOfTargetsSelected === 1;
    },
    numberOfTargetsSelected() {
      if (!this.selectedConfiguration?.specificFeaturesSelected) return 0;
      const targetCount = Object.values(this.selectedConfiguration.specificFeaturesSelected)
        .filter((value) => value === 'target').length;
      return targetCount;
    },
    graphsToExplain() {
      // take selected configurations, and return an array of the objects for the explanation panel
      const graphsToReturn = [];
      this.configurations.forEach((configuration, index) => {
        if (configuration.configurationSelected) {
          const featuresInUse = Object.entries(configuration.specificFeaturesSelected)
            .filter(([, value]) => value === 'feature')
            .map(([key]) => key);
          const targetsInUse = Object.entries(configuration.specificFeaturesSelected)
            .filter(([, value]) => value === 'target')
            .map(([key]) => key);
          const graphToReturn = {
            modelType: `${configuration.modelSelected} with features ${featuresInUse}, targets of ${targetsInUse}, and the data file ${configuration.inputDataSelected}`,
            typeOfGraphToExplain: configuration.importanceSelected,
            graphToExplain: this.svgOutputs[index],
          };
          graphsToReturn.push(graphToReturn);
        }
      });
      return graphsToReturn;
    },
    configurationToUse() {
      return this.modelSelected === 'decisionTree' ? this.inspectorTreeConfiguration : this.inspectorForestConfiguration;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    addConfiguration() {
      const newConf = {
        ...this.configurationToUse,
        modelSelected: this.modelSelected,
        importanceSelected: this.importanceSelected,
        configurationSelected: true,
      };
      this.configurations.push(newConf);
      this.svgOutputs.push('');
      this.stats.push('');
      this.configurationsLoading.push(true);

      this.fetchFeatureImportance();
    },
    validConfiguration(conf) {
      if (conf.modelSelected === 'decisionTree') {
        return this.targetsInUse(conf)?.length
        && this.featuresInUse(conf)?.length;
      }
      return this.targetsInUse(conf)?.length === 1
        && this.featuresInUse(conf)?.length;
    },
    isClickInsideCard(event) {
      return this.$refs.configPanels?.some((panel) => panel.contains(event.target));
    },
    deselectAllConfigurations() {
      this.configurations = this.configurations.map((conf) => ({
        ...conf,
        configurationSelected: false,
      }));
    },
    handleOutsideClick(event) {
      if (!this.isClickInsideCard(event)
        && !this.$refs.inspector.contains(event.target)
        && !this.$refs.explanation_section.contains(event.target)
        && !this.$refs.addButton.contains(event.target)
        && !this.$refs.deleteButton.contains(event.target)
        && !this.$refs.addButton.contains(event.target)) {
        this.deselectAllConfigurations();
      }
    },
    modelsChanged() {
      if (this.modelSelected !== 'decisionTree') {
        if (this.importanceSelected === 'gini' || this.importanceSelected === 'dtreeviz') {
          this.importanceSelected = 'shapley_summary';
        }
      }

      if (!this.isAnyConfigurationSelected) return;

      this.configurations = this.configurations.map((conf) => {
        if (conf.configurationSelected) {
          return {
            ...this.configurationToUse,
            configurationSelected: true,
            modelSelected: this.modelSelected,
            importanceSelected: this.importanceSelected,
          };
        }
        return conf;
      });
      this.fetchFeatureImportance();
    },
    updateTreeConfiguration(newConfiguration) {
      this.inspectorTreeConfiguration = newConfiguration;
      this.updateSelectedConfigurations(newConfiguration);
      this.fetchFeatureImportance();
    },
    updateForestConfiguration(newConfiguration) {
      this.inspectorForestConfiguration = newConfiguration;
      this.updateSelectedConfigurations(newConfiguration);
      this.fetchFeatureImportance();
    },
    updateSelectedConfigurations(newConfiguration) {
      if (!this.isAnyConfigurationSelected) return;

      this.configurations = this.configurations.map((conf) => {
        if (conf.configurationSelected) {
          // eslint-disable-next-line vue/max-len
          return {
            ...newConfiguration,
            modelSelected: this.modelSelected,
            importanceSelected: this.importanceSelected,
            configurationSelected: true,
          };
        }
        return conf;
      });
    },
    toggleSelectConfiguration(index) {
      const updatedConf = { ...this.configurations[index] };
      updatedConf.configurationSelected = !updatedConf.configurationSelected;
      this.configurations[index] = updatedConf;
    },
    trashSelectedConfigurations() {
      // also trash svgs and stats
      // eslint-disable-next-line vue/max-len
      this.svgOutputs = this.svgOutputs.filter((_, index) => !this.configurations?.[index]?.configurationSelected);
      // eslint-disable-next-line vue/max-len
      this.stats = this.stats.filter((_, index) => !this.configurations?.[index]?.configurationSelected);
      this.configurations = this.configurations?.filter((conf) => !conf?.configurationSelected);
      this.configurationsLoading = this.configurationsLoading.filter((_, index) => !this.configurations?.[index]?.configurationSelected);
    },
    openSVG(svgData) {
      openSVGInNewTab(svgData);
    },
    async fetchTreeStats(conf) {
      if (!this.validConfiguration(conf)) return {};
      const randomStateComputed = conf.randomStateSelected ?? 0;
      const url = [
        `api/tree/${conf.inputDataSelected}/?export_type=JSON`,
        `&max_depth=${conf.maxTreeDepth}`,
        `&criterion=${conf.criterionSelected}`,
        `&splitter=${conf.splitterSelected}`,
        `&test_train_split=${conf.testTrainSplit}`,
        `&random_state=${randomStateComputed}`,
      ].join('');

      const decisionTreeGraphResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            specific_features: conf.specificFeaturesSelected,
          },
        ),
      });
      if (decisionTreeGraphResponse.status !== 200) throw new Error(`👺 ${decisionTreeGraphResponse.status} ${decisionTreeGraphResponse.statusText}`);
      const decisionTreeGraph = await decisionTreeGraphResponse.json();
      return decisionTreeGraph.tree_stats;
    },
    // eslint-disable-next-line no-empty-function
    async fetchFeatureImportance() {
      if (!this.isAnyConfigurationSelected) return;
      Promise.all(this.configurations.map(async (conf, index) => {
        if (!conf.configurationSelected || !this.validConfiguration(conf)) return;
        this.configurationsLoading[index] = true;
        const randomStateComputed = conf.randomStateSelected ? conf.randomStateSelected : null;
        if (conf.modelSelected === 'decisionTree') {
          [this.svgOutputs[index]] = await getTreeFeatureImportanceGraph({
            ...conf,
            importanceSelected: this.importanceSelected,
            randomStateSelected: randomStateComputed,
            includeLegend: this.includeLegend,
          });
          this.stats[index] = await this.fetchTreeStats(conf);
        } else {
          [this.svgOutputs[index]] = await getForestFeatureImportanceGraph({
            ...conf,
            importanceSelected: this.importanceSelected,
            randomStateSelected: randomStateComputed,
            includeLegend: this.includeLegend,
          });

          this.stats[index] = await getForestStats({
            ...conf,
            importanceSelected: this.importanceSelected,
            randomStateSelected: randomStateComputed,
          });
        }
        this.configurationsLoading[index] = false;
      }));
    },
    targetLabels(conf) {
      return this.targetsInUse(conf).join(', ');
    },
    targetsInUse(conf) {
      const targetsInUse = Object.entries(conf.specificFeaturesSelected)
        .filter(([, value]) => value === 'target')
        .map(([key]) => key);
      return targetsInUse;
    },
    featuresInUse(conf) {
      const featuresInUse = Object.entries(conf.specificFeaturesSelected)
        .filter(([, value]) => value === 'feature')
        .map(([key]) => key);
      return featuresInUse;
    },
  },
};
</script>

<style scoped>

.svg-content svg {
  width: 200px;
  height: 400px;
}

.smaller-text {
  font-size: 0.7rem;
}

.border-selected {
  border: 3px solid var(--bs-primary); /* Blue color for selected */
}
.btn-info {
  margin-right: 2px;
}
</style>
