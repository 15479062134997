<!-- eslint-disable vue/max-len -->
<template>
  <div class="container-responsive">
    <div class="row" aria-label="Canvas and Settings">
      <div class="col-lg-10" aria-label="Main Canvas">
        <LoadingSpinner v-if="loading" />
        <div class="svg-container" v-html="mainCanvasSVG" />
      </div>
      <div class="col-lg-2" aria-label="Forest Settings">
        <h6 class="row">Decision Forest Controls</h6>
        <decision-forest-controls
          @controlsUpdated="updateDecisionForest"
        />
        <div class="row mb-3">
          <div class="col form-group">
            <label for="importanceSelector" class="form-label smaller-text">Auxiliary Graphs:</label>
            <select
              class="form-select form-select-sm"
              id="importanceSelector"
              v-model="importanceSelected"
              @change="updateDecisionForest(forestConfiguration)">
              <option value="shapley_summary">Shapley Summary</option>
              <option value="shapley_beeswarm">Shapley Beeswarm</option>
              <option value="shapley_scatter">Shapley Scatter</option>
            </select>
          </div>
        </div>
        <div class="form-check mt-2">
          <label class="smaller-text" for="includeLegend">
            Include Legend
          </label>
          <input
            class="form-check-input"
            type="checkbox"
            id="includeLegend"
            v-model="includeLegend"
            @change="includeLegendChanged">
        </div>
        <div class="row">
          <PredictionPanel
            :features="featuresSelected"
            :metaData="metaDataSelected"
            @clearedPrediction="clearPrediction"
            @predictionToBeGenerated="predictionPressed" />
        </div>
        <div class="row" v-if="prediction">
          <div class="col-sm-12">
            <h6>Prediction</h6>
            <div class="btn-group btn-group-sm btn-group-vertical" role="group">
              <button
                type="button"
                class="btn btn-secondary interaction-button"
                v-for="(predictionResult, index) in prediction"
                :class="{ active: selectedPredictionIndex === index }"
                :key="predictionResult.target"
                :id="predictionResult.target"
                @click="changePredictionSelection">
                {{ predictionResult.target }}: {{ (predictionResult.probability * 100).toFixed(1) }}%
              </button>
            </div>
          </div>
          <div
            v-if="forceGraphSVG"
            class="short-svg-container forceGraphSVG row mt-2"
            @click="openSVG(forceGraphSVG)"
            @keypress="openSVG(forceGraphSVG)">
            <div v-html="forceGraphSVG" />
          </div>
          <div
            v-if="featureImportanceSVG"
            class="svg-container predictionFeatureImportanceSVG row mt-2"
            @click="openSVG(featureImportanceSVG)"
            @keypress="openSVG(featureImportanceSVG)">
            <div v-html="featureImportanceSVG" />
          </div>
        </div>
        <div class="row mt-2" v-if="forestStats">
          <h6>Decision Forest Statistics</h6>
          <div class="smaller-text">
            <p>Test Accuracy: {{ (forestStats.test_accuracy * 100).toFixed(1) }}%</p>
            <p>Training Accuracy: {{ (forestStats.training_accuracy * 100).toFixed(1) }}%</p>
            <p>Total Trees Used: {{ forestStats.total_num_trees }}</p>
          </div>
        </div>
      </div>
    </div>
    <explanation-panel
      :graphs-to-explain="graphsToExplain"
    />

  </div>
</template>

<script>
import DecisionForestControls from './DecisionForestControls.vue';
import ExplanationPanel from './ExplanationPanel.vue';
import PredictionPanel from './PredictionPanel.vue';
import LoadingSpinner from './LoadingSpinner.vue';

import {
  getForestFeatureImportanceGraph,
  getForestStats, openSVGInNewTab, generateForestPrediction,
} from './lib/utils';

export default {
  components: {
    DecisionForestControls,
    ExplanationPanel,
    PredictionPanel,
    LoadingSpinner,
  },
  computed: {
    graphsToExplain() {
      return [{
        modelType: `XGBoost with features ${this.featuresSelected}, targets of ${this.targetSelected}, and the data file ${this.forestConfiguration?.inputDataSelected}`,
        typeOfGraphToExplain: this.typeOfGraphToExplain,
        graphToExplain: this.mainCanvasSVG,
      }];
    },
    metaDataSelected() {
      // eslint-disable-next-line vue/max-len
      const metaData = this.forestConfiguration?.inputDataOptions?.find((inputDataOption) => inputDataOption.filename === this.forestConfiguration.inputDataSelected)?.metadata;
      return metaData;
    },
    featuresSelected() {
      if (!this.forestConfiguration?.specificFeaturesSelected) return [];
      const featuresInUse = Object.entries(this.forestConfiguration.specificFeaturesSelected)
        .filter(([, value]) => value === 'feature')
        .map(([key]) => key);
      return featuresInUse;
    },
    targetSelected() {
      if (!this.forestConfiguration?.specificFeaturesSelected) return [];
      const targetsInUse = Object.entries(this.forestConfiguration.specificFeaturesSelected)
        .filter(([, value]) => value === 'target')
        .map(([key]) => key);
      // should only be one target
      if (targetsInUse.length === 1) return targetsInUse[0];
      return null;
    },
    mainCanvasSVG() {
      if (this.prediction && this.waterfallGraphSVG) {
        return this.waterfallGraphSVG;
      }
      return this.featureImportanceSVG;
    },
    typeOfGraphToExplain() {
      if (this.prediction && this.waterfallGraphSVG) {
        return `shapley_waterfall for ${this.prediction[this.selectedPredictionIndex].target}`;
      }
      return this.importanceSelected;
    },
    forceGraphSVG() {
      if (this.forceGraphs && this.selectedPredictionIndex < this.forceGraphs.length) {
        return this.forceGraphs[this.selectedPredictionIndex];
      }
      return null;
    },
    waterfallGraphSVG() {
      if (this.waterfallGraphs && this.selectedPredictionIndex < this.waterfallGraphs.length) {
        return this.waterfallGraphs[this.selectedPredictionIndex];
      }
      return null;
    },
    validConfiguration() {
      return this.featuresSelected.length && this.targetSelected;
    },
  },
  data() {
    return {
      forestConfiguration: {},
      importanceSelected: 'shapley_summary',
      featureImportanceSVG: null,
      forestStats: null,
      prediction: null,
      waterfallGraphs: [],
      forceGraphs: [],
      selectedPredictionIndex: 0,
      loading: false,
      includeLegend: true,
      selectedFeatureValues: {},
    };
  },
  methods: {
    async updateDecisionForest(incomingForestConfiguration) {
      this.loading = true;
      this.forestConfiguration = incomingForestConfiguration;
      // clear everything out
      this.featureImportanceSVG = null;
      this.forestStats = null;
      this.prediction = null;
      this.waterfallGraphs = null;
      this.forceGraphs = null;

      // check to see if incomingForestConfiguration has one feature and one target at least
      if (!this.validConfiguration) {
        return;
      }

      this.forestConfiguration = incomingForestConfiguration;

      const featureImportanceSVGs = await getForestFeatureImportanceGraph({
        ...this.forestConfiguration,
        importanceSelected: this.importanceSelected,
      });

      // always choose the first for the overall graph
      [this.featureImportanceSVG] = featureImportanceSVGs;

      this.forestStats = await getForestStats({
        ...this.forestConfiguration,
        importanceSelected: this.importanceSelected,
      });
      this.featureValues = this.forestStats?.metadata?.ordinal_mapping;
      this.loading = false;
    },
    changePredictionSelection(event) {
      this.selectedPredictionIndex = this.prediction.findIndex(
        (predictionResult) => predictionResult.target === event.target.id,
      );
    },
    clearPrediction() {
      this.prediction = null;
      this.waterfallGraphs = null;
      this.forceGraphs = null;
    },
    predictionPressed(incomingSelectedFeatureValues) {
      if (incomingSelectedFeatureValues) {
        this.selectedFeatureValues = incomingSelectedFeatureValues;
      }
      this.generatePrediction(this.selectedFeatureValues);
    },
    async includeLegendChanged() {
      if (!this.validConfiguration) {
        return;
      }
      await this.generatePrediction();
      this.loading = true;
      const featureImportanceSVGs = await getForestFeatureImportanceGraph({
        ...this.forestConfiguration,
        importanceSelected: this.importanceSelected,
        includeLegend: this.includeLegend,
      });

      // always choose the first for the overall graph
      [this.featureImportanceSVG] = featureImportanceSVGs;

      this.loading = false;
    },
    async generatePrediction() {
      if (!this.validConfiguration || Object.keys(this.selectedFeatureValues).length === 0) return;

      this.loading = true;
      this.prediction = await generateForestPrediction({
        selectedFeatureValues: this.selectedFeatureValues,
        forestConfiguration: this.forestConfiguration,
        metaDataSelected: this.metaDataSelected,
        targetSelected: this.targetSelected,
        importanceSelected: this.importanceSelected,
      });
      this.selectedPredictionIndex = 0;
      this.fetchFeatureGraphs(this.selectedFeatureValues);
    },
    async fetchFeatureGraphs(selectedFeatureValues) {
      // check to see if incomingForestConfiguration has one feature and one target at least
      if (!this.validConfiguration) {
        return;
      }
      this.loading = true;
      const optionsForFeatureImportanceGraph = {
        ...this.forestConfiguration,
        importanceSelected: this.importanceSelected,
        featureValues: selectedFeatureValues,
        includeLegend: this.includeLegend,
      };

      this.forceGraphs = await getForestFeatureImportanceGraph({
        ...optionsForFeatureImportanceGraph,
        importanceSelected: 'shapley_force',
        includeLegend: this.includeLegend,
      });

      this.waterfallGraphs = await getForestFeatureImportanceGraph({
        ...optionsForFeatureImportanceGraph,
        importanceSelected: 'shapley_waterfall',
        includeLegend: this.includeLegend,
      });
      this.loading = false;
    },
    openSVG(featureImportanceSVG) {
      openSVGInNewTab(featureImportanceSVG);
    },
  },
};
</script>

<style scoped>
.forest-svg-container {
  border: 1px solid lightgrey;
  max-width: 100%;
  overflow: auto;
}

.shapley-container {
  border: 1px solid lightgrey;
  max-width: 100%;
  margin-top: 20px;
  overflow: auto;
}
.smaller-text {
  font-size: 0.7rem;
}
.interaction-button {
  width: 100%;
  white-space: nowrap;
}
.loading-spinner {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
</style>
