<template>
  <div v-if="!hideInputs" class="row">
    <FeatureAndInputSelector
      :multiSelectTargets="true"
      @controlsUpdated="featuresAndInputUpdated"
    />
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="maxTreeDepth" class="form-label smaller-text">Tree Depth:</label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="maxTreeDepth"
          min="1"
          step="1"
          v-model="maxTreeDepth"
          :disabled="disabled"
          @change="emitUpdate" />
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="testTrainSplit" class="form-label smaller-text">Test/Train split:</label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="testTrainSplit"
          min="0"
          max="0.99"
          step="0.1"
          :disabled="disabled"
          v-model="testTrainSplit"
          @change="emitUpdate" />
      </div>
    </div>
  </div>
  <div class="row">
    <div v-if="!hideLayout" class="col-6">
      <div class="form-group">
        <label for="layoutSelector" class="form-label smaller-text">Layout:</label>
        <select
          class="form-select form-select-sm"
          id="layoutSelector"
          :disabled="disabled"
          v-model="selectedLayout"
          @change="emitUpdate">
          <option value="dagre">Dagre</option>
          <option value="elk">Elk</option>
          <option value="grid">Grid</option>
          <option value="circle">Circle</option>
          <option value="concentric">Concentric</option>
          <option value="avsdf">AVSDF</option>
          <option value="breadthfirst">Breadthfirst</option>
          <option value="cola">Cola</option>
          <option value="fcose">FCOSE</option>
          <option value="cise">CISE</option>
          <option value="euler">Euler</option>
          <option value="spread">Spread</option>
        </select>
      </div>
    </div>
    <div :class="{ 'col-12': hideLayout, 'col-6': !hideLayout }">
      <div class="form-group">
        <label for="randomState" class="form-label smaller-text">Random State:</label>
        <input
          type="number"
          class="form-control form-control-sm"
          id="randomStateSelector"
          step="1"
          placeholder="None"
          v-model="randomStateSelected"
          :disabled="disabled"
          @change="emitUpdate" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="criterionSelector" class="form-label smaller-text">Criterion:</label>
        <select
          class="form-select form-select-sm"
          id="criterionSelector"
          v-model="criterionSelected"
          :disabled="disabled"
          @change="emitUpdate">
          <option value="gini">Gini</option>
          <option value="entropy">Entropy</option>
          <option value="log_loss">Log Loss</option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="splitterSelector" class="form-label smaller-text">Splitter:</label>
        <select
          class="form-select form-select-sm"
          id="splitterSelector"
          v-model="splitterSelected"
          :disabled="disabled"
          @change="emitUpdate">
          <option value="best">Best</option>
          <option value="random">Random</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label for="splitterSelector" class="form-label smaller-text">Splitter:</label>
      <select
        class="form-select form-select-sm"
        id="splitterSelector"
        v-model="splitterSelected"
        :disabled="disabled"
        @change="emitUpdate">
        <option value="best">Best</option>
        <option value="random">Random</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="form-group">
      <label class="form-label smaller-text" for="toggleNodeDisplay"> Display Test on Nodes: </label>
      <input
        type="checkbox"
        class="form-check-input"
        id="toggleNodeDisplay"
        v-model="showTestInsteadOfLabel"
        :disabled="disabled"
        @change="emitUpdate" />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import FeatureAndInputSelector from './FeatureAndInputSelector.vue';

export default {
  name: 'DecisionTreeControls',
  emits: ['controlsUpdated'],
  components: {
    FeatureAndInputSelector,
  },
  props: {
    hideInputs: {
      type: Boolean,
      default: false,
    },
    hideLayout: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxTreeDepth: 3,
      selectedLayout: 'dagre',
      randomStateSelected: 0,
      criterionSelected: 'gini',
      splitterSelected: 'best',
      testTrainSplit: 0.2,
      inputDataSelected: null,
      inputDataOptions: [],
      specificFeaturesSelected: {},
      showTestInsteadOfLabel: false,
    };
  },
  mounted() {
    if (this.hideInputs) {
      this.emitUpdate();
    }
  },
  methods: {
    emitUpdate: debounce(function emitUpdate() {
      this.$emit('controlsUpdated', this.$data);
    }, 750),
    featuresAndInputUpdated(newFeaturesData) {
      this.specificFeaturesSelected = newFeaturesData.specificFeaturesSelected;
      this.inputDataSelected = newFeaturesData.inputDataSelected;
      this.inputDataOptions = newFeaturesData.inputDataOptions;
      this.emitUpdate();
    },
  },
};
</script>

<style scoped>
  .smaller-text {
        font-size: 0.7rem;
    }
</style>
